import React, {useEffect} from 'react';
import Head from "../components/Head";
import Layout from "../components/Layout";

const NotFoundPage = () => {

  useEffect(() => {
    let navBar = document.getElementById("mainNav");
    navBar.classList.add("navbar-shrink");
  }, []);

    return (
        <Layout>
          <style dangerouslySetInnerHTML={{__html: `
              main > .container {
                  padding: 150px 15px 0;
              }
          `}} />
          <Head pageTitle="Page not found" title="Page not found" path="/" description="Page not found" imageUrl="" type="website" datePublished="01/04/2022" />
          <main class="flex-grow-1 bg-light">
            <div className="container">
              <section>
                <h1>Page not found</h1>
              </section>
            </div>
          </main>
        </Layout>
    );
}

export default NotFoundPage;
